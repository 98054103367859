import React from 'react';
import data from '../../api/blogdata.json';
import Layout from '../../components/Layout';
import CookieConcent from '../../components/CookieConcent';


const HomePage = () => {

    return (
        <Layout>
            <div>
                <CookieConcent />
                {/* Welcome Section */}
                <section className="py-12 text-center text-slate-600 p-3">
                    <h2 className=" text-4xl font-bold mb-4">Välkommen till min blog.</h2>
                    <p className="text-slate-600">
                        Här är platsen där jag delar med mig av mina tankar, erfarenheter och passioner.
                    </p>

                </section>
                {/* Grid Section */}
                <section className="py-2">
                    <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        {data.map(item => (
                            <div className='p-3' key={item.id} src={item.bild}>
                                <img src={item.bild} className="rounded" alt={item.alt} />
                                <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                                <p className="text-gray-dark">{item.description}</p>
                                <a href={item.linc} className="hover:underline"><p className='font-bold text-slate-600'>Läs mer...</p></a>
                            </div>
                        ))}
                    </div>
                </section>
            </div>

        </Layout>
    );
};

export default HomePage;

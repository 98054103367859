import React from 'react';
import Layout from '../components/Layout';
import CookieConcent from '../components/CookieConcent';

function StartPage() {
    return (
        <Layout>
            <div>
                <CookieConcent />
                {/* Welcome Section */}
                <section className="py-16 text-slate-600 p-3">
                    <h2 className="text-4xl font-bold mb-4">Välkommen till min site.</h2>
                    <img src='/images/me-at-79.jpg' className="rounded" alt="En lite justerad bild av mig!" />
                    <p className="text-slate-600 py-10">
                        Det här är platsen där jag delar med mig av mina tankar, erfarenheter och passioner. Oavsett om du är intresserad av politik, matlagning, hur datorn funkar eller hur det är att bo i Portugal, så hoppas jag att du hittar något inspirerande här..
                    </p>
                    <ul>
                        <li>
                            <a href='/blog' className='text-2xl'>Till Blog &rarr;</a>
                        </li>
                        <li>
                            <a href='/aktier' className='text-2xl'>Till Aktier &rarr;</a>
                        </li>
                    </ul>

                    {/* Footer Section */}
                    <section className="py-10 text-slate-600">
                        <p className="text-slate-600">
                            P.S. Jag har byggt denna sida med React och Tailwind CSS för att skapa en responsiv och modern webbsida. Jag har använt mig av en API för att hämta innehållet från en blogg. Jag har även använt mig av en cookie-banner för att informera användare om att de här sidorna använder cookies. D.S.
                        </p>
                    </section>
                </section>
            </div>

        </Layout >
    );
};

export default StartPage;

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';


function CookiePage() {
    const markdownContent = `
## Cookiepolicy

Jag använder cookies för att:  
- Förbättra webbplatsens funktionalitet.  
- Analysera besökstrafik via Google Analytics.  

### Typer av cookies  
- **Nödvändiga:** För att webbplatsen ska fungera korrekt.  
- **Statistik:** För att förstå hur vår webbplats används.

Du kan ändra eller återkalla ditt samtycke via cookie-inställningarna i din webbläsare.  
`;
    return (
        <Layout>
            <div>
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-4xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-3xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-2xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/Forville-2013-12-27.jpg' className="rounded" height="500px" width="600px" alt="En marknad i Cannes!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default CookiePage;





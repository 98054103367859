import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';
import CookieBanner from '../../components/CookieConcent';

function VardiutlandetPage() {
    const markdownContent = `
![A scene depicting a doctor in a white coat consulting with a typical French man in a medical office. The doctor, a middle-aged Caucasian female!](/images/doktor.jpg)

# Hur vårdas folk i södra Europa

> Att förstå hur hälso- och sjukvårdssystemen fungerar i olika länder är avgörande, särskilt för dem som planerar att bo, arbeta eller resa inom Europa. Denna sida ger en översikt över vårdsystemen i Frankrike och Portugal, inklusive deras struktur, finansiering, försäkringssystem och tillgängliga tjänster.

### Vården i Frankrike

#### Struktur
> Frankrike har ett av de mest respekterade hälso- och sjukvårdssystemen i världen, känt som **Sécurité Sociale**. Systemet är decentraliserat, vilket innebär att regionala myndigheter har stor autonomi när det gäller drift och administration av vårdtjänster.

#### Finansiering
> Finansieringen sker huvudsakligen genom sociala avgifter som betalas av både arbetsgivare och anställda. Staten bidrar också betydligt till finansieringen av sjukvården. Det finns dessutom privata utgifter, men de är relativt låga jämfört med offentliga kostnader.

#### Försäkringssystem
> Det franska försäkringssystemet är omfattande och täcker majoriteten av medicinska kostnader. Patienter betalar vanligtvis en del av kostnaderna själva, men en kompletterande privat försäkring (**mutuelle**) kan täcka resterande belopp.

#### Tjänster
> Systemet erbjuder ett brett spektrum av tjänster, inklusive primärvård, specialistvård, sjukhusvård, och förebyggande tjänster. Det finns också en stark betoning på kvalitetskontroll och patientens rättigheter.

### Vård i Portugal

#### Struktur
> Portugal har ett universellt hälso- och sjukvårdssystem kallat **Serviço Nacional de Saúde (SNS)**. SNS är ansvarigt för att tillhandahålla vård till alla invånare, oavsett inkomst eller social status.

#### Finansiering
> Systemet finansieras huvudsakligen genom skatter, vilket säkerställer att grundläggande vårdtjänster är tillgängliga för alla. Det finns också möjlighet till privata sjukförsäkringar för dem som önskar snabbare tillgång till vissa tjänster eller privata faciliteter.

#### Försäkringssystem
> SNS täcker de flesta grundläggande vårdtjänster, inklusive läkarkonsultationer, sjukhusvistelser, och nödvändig medicin. Precis som i Frankrike kan patienter välja att teckna privata försäkringar för att få tillgång till ytterligare tjänster eller snabbare vård.

#### Tjänster
> Portugal erbjuder omfattande vårdtjänster som inkluderar primärvård, specialistvård, psykiatrisk vård, och rehabiliteringstjänster. Landet har också satsat på digitalisering av vården för att förbättra tillgängligheten och effektiviteten.


### Jämförelse mellan Frankrike och Portugal
>
| **Faktor**               | **Frankrike**                               | **Portugal**                                |
|--------------------------|---------------------------------------------|---------------------------------------------|
| **Finansiering**         | Sociala avgifter och statliga bidrag        | Skatter och privata försäkringar            |
| **Försäkringssystem**    | Sécurité Sociale med kompletterande mutuelle | Serviço Nacional de Saúde med möjlighet till privata försäkringar |
| **Tjänster**             | Omfattande med stark kvalitetskontroll      | Universellt med fokus på tillgänglighet      |
| **Tillgång**             | Hög tillgänglighet till vårdgivare          | Bra täckning, särskilt i urbana områden      |
| **Kostnader för patient**| Delvis egenavgifter, kompletteras av försäkring | Låg egenavgift, extra via privata försäkringar |
>
### Patientens Roll och Delaktighet
>En viktig aspekt som ofta uppmärksammas i Frankrike och Portugal är den höga graden av patientdelaktighet i vården. Många användare upplever att vården i dessa länder bedrivs tillsammans med patienten i högre grad än vad som är vanligt i Sverige.

#### Frankrike
>I Frankrike är patientens rättigheter starkt skyddade, och det finns en tydlig struktur för att involvera patienter i beslutsprocesser kring deras egen vård. Läkare uppmuntras att diskutera behandlingsalternativ och lyssna på patientens preferenser och behov, vilket främjar en mer partnerskapsbaserad relation.

#### Portugal
>Portugal betonar också vikten av patientens delaktighet genom att erbjuda utbildning och information som gör det möjligt för patienter att fatta informerade beslut om sin hälsa. Digitala verktyg och plattformar har implementerats för att underlätta kommunikationen mellan patienter och vårdgivare, vilket ytterligare stärker samarbetsklimatet.

#### Sverige
>I Sverige är patientdelaktighet en central princip inom vården, men upplevelsen kan variera beroende på region och vårdgivare. Vissa patienter kan uppleva att de har mindre inflytande eller att kommunikationen inte är lika omfattande som i Frankrike och Portugal. Detta kan bero på olika organisatoriska strukturer och kulturella skillnader i hur vårdteam interagerar med patienter.

### Slutsats

>Både Frankrike och Portugal erbjuder robusta hälso- och sjukvårdssystem som syftar till att tillhandahålla högkvalitativ vård till sina invånare. En framträdande skillnad är den höga graden av patientdelaktighet i Frankrike och Portugal jämfört med Sverige. Frankrikes system är känt för sin omfattande försäkring och höga kvalitet, medan Portugal betonar universell tillgång och effektivitet genom skattefinansiering. 

>Den ökade delaktigheten i vården i Frankrike och Portugal kan bidra till högre patientnöjdhet och bättre anpassade vårdlösningar. I Sverige kan detta vara ett område för vidare utveckling, där fler initiativ kan implementeras för att stärka patientens roll och förbättra kommunikationen mellan vårdgivare och patienter.

Oavsett vilket land du befinner dig i, är det viktigt att känna till hur vårdsystemet fungerar, vilka rättigheter du har som patient, och vilka möjligheter till kompletterande försäkringar som finns tillgängliga.

---

**För ytterligare information:**

- **Frankrikes Sécurité Sociale:** [Officiell webbplats](https://www.ameli.fr/)
- **Portugals Serviço Nacional de Saúde:** [Officiell webbplats](https://www.sns.gov.pt/)

Om du planerar att flytta till eller besöka Frankrike eller Portugal, rekommenderas det att noggrant undersöka de lokala hälso- och sjukvårdstjänsterna och eventuella krav på försäkringar för att säkerställa att du har adekvat skydd och tillgång till vård vid behov.
>
`;
    return (
        <Layout>
            <div className="max-w-[800px] py-12">
                <CookieBanner />
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-4xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-3xl font-semibold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-2xl font-medium mb-4">{children}</h3>,
                        p: ({ children }) => <p className="mb-4 ml-5">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src="/images/doktor.jpg" className="rounded " alt="French doktor">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}

export default VardiutlandetPage;
import React from "react";

const Header = () => {
    return (
        <header className="bg-mocha text-white py-4 shadow-md mb-1">
            <div className="container mx-auto px-4 flex justify-between items-center">
                <h1 className="text-2xl text-white font-bold"><a href="https://ulferik.se">Ulf's Website</a></h1>
                <nav>
                    <ul className="flex space-x-4">
                        <li>
                            <a href="/" className="hover:underline">
                                Startsidan
                            </a>
                        </li>
                        <li>
                            <a href="/blog" className="hover:underline">
                                Blog
                            </a>
                        </li>
                        <li>
                            <a href="/aktier" className="hover:underline">
                                Aktier
                            </a>
                        </li>
                        <li>
                            <a href="/about" className="hover:underline">
                                Om mig
                            </a>
                        </li>
                        <li>
                            <a href="mailto:ulferik+web@pm.me" className="hover:underline">
                                Kontakt
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
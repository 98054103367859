import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';
import CookieBanner from "../../components/CookieConcent";

function RatePage() {
    const markdownContent = `
# Räntor kan komma att bli ett stort problem

USA:s statsskuld överstiger nu 100 % av BNP, vilket innebär att skulden är större än landets årliga ekonomiska produktion. Ökningen, från cirka 70 % för 15 år sedan till över 122 % år 2023, drivs av finanskrisen 2008, pandemistimulanser och återkommande budgetunderskott. Hög skuldsättning medför risker som ökade ränteutgifter, minskat finanspolitiskt utrymme och potentiella kreditvärderingsnedgraderingar. USA:s ränteutgifter är nu högre än försvarsbudgeten, vilket väcker oro för långsiktig hållbarhet. Trots detta har USA historiskt hanterat skulden genom ekonomisk tillväxt och skatteintäkter, men fortsatt skulduppbyggnad utan motsvarande tillväxt kan leda till allvarliga utmaningar framöver.

# "**Bond vigilantes**" 
Är ett uttryck som beskriver investerare i obligationsmarknaden som genom sitt beteende påverkar ett lands ekonomiska politik. 
Det myntades på 1980-talet och används för att beskriva hur obligationsmarknaden kan "straffa" regeringar som bedriver vad investerarna anser vara ohållbar ekonomisk politik, exempelvis genom stora budgetunderskott eller snabb skulduppbyggnad.

### Hur fungerar det?
När obligationsinvesterare förlorar förtroendet för ett lands ekonomiska politik:
- **Säljer de sina obligationer**, vilket får obligationspriserna att falla och räntorna att stiga.  
- **Högre räntor** ökar statens lånekostnader, vilket kan sätta press på regeringen att ändra sin politik för att lugna marknaderna.
>

### Exempel på effekter:
1. **USA på 1980-talet**: Uttrycket populariserades under denna period, när höga budgetunderskott ledde till stigande räntor och press på politiska förändringar.  
2. **Eurokrisen (2010-talet)**: Länder som Grekland, Spanien och Italien såg kraftiga räntehöjningar när obligationsinvesterare förlorade förtroendet för deras skuldhantering.
>
### Symbolik
"Vigilantes" refererar till självutnämnda vakter som genom sina åtgärder försöker "disciplinera" regeringar som inte verkar ansvarsfullt. Det visar hur marknaden kan ha en betydande roll som motkraft mot politiska beslut.
`;
    return (
        <Layout>
            <div className='py-12'>
                <CookieBanner />
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-4xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-3xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-2xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="border-collapse">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="bg-gray-200 px-4">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="px-4">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/Richeman.jpg' className="rounded" height="150px" width="160px" alt="En marknad i Cannes!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default RatePage;
